exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-event-tsx": () => import("./../../../src/pages/event.tsx" /* webpackChunkName: "component---src-pages-event-tsx" */),
  "component---src-pages-favorites-tsx": () => import("./../../../src/pages/favorites.tsx" /* webpackChunkName: "component---src-pages-favorites-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-partnership-tsx": () => import("./../../../src/pages/partnership.tsx" /* webpackChunkName: "component---src-pages-partnership-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-recipe-tsx": () => import("./../../../src/pages/recipe.tsx" /* webpackChunkName: "component---src-pages-recipe-tsx" */),
  "component---src-pages-share-list-[hash]-tsx": () => import("./../../../src/pages/share/list/[hash].tsx" /* webpackChunkName: "component---src-pages-share-list-[hash]-tsx" */),
  "component---src-pages-v-2-index-tsx": () => import("./../../../src/pages/v2/index.tsx" /* webpackChunkName: "component---src-pages-v-2-index-tsx" */),
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-marketplace-tsx": () => import("./../../../src/templates/marketplace.tsx" /* webpackChunkName: "component---src-templates-marketplace-tsx" */),
  "component---src-templates-partnership-tsx": () => import("./../../../src/templates/partnership.tsx" /* webpackChunkName: "component---src-templates-partnership-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}

