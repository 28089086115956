module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"logCiCa Explore","short_name":"Explore","start_url":"/","background_color":"#FFFFFF","theme_color":"#ffcb01","display":"standalone","icon":"src/images/favicon.svg","screenshots":[{"src":"/img/explore-v2-large.png","sizes":"800x385","type":"image/png","label":"Application"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0e7ff8a589bce72c7fc3e19681747377"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr","en"],"defaultLanguage":"fr","siteUrl":"https://explore.logcica.org/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"defaultNS":"common"},"pages":[{"matchPath":"/v2","languages":["fr","en"]},{"matchPath":"/:any","languages":["fr"]},{"matchPath":"/:any/:any1","languages":["fr"]},{"matchPath":"/:any/:any1/:any2","languages":["fr"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
